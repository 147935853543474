/* eslint-disable @next/next/no-img-element */
import { useIsLoggedInQuery } from 'auth/hooks'
import { SkeletonItem } from 'components/skeleton-item'
import { useLocale, useTranslations } from 'next-intl'
import * as Progress from 'primitives/progress'
import { Tag } from 'primitives/tag'
import * as React from 'react'
import { tw } from 'utils/classnames'
import { useAskCfGetUserStatsQuery, useUserProfilePanelQuery } from 'codegen/generated/ask-cf'

import { Link } from '@ask-cf/components/link'
import { ContactSupportIcon, SettingsIcon } from '@ask-cf/assets/icons'
import BlueHollowStarImage from '@ask-cf/assets/images/blue_hollow_star.svg'
import PinkHollowStarImage from '@ask-cf/assets/images/pink_hollow_star.svg'
import PinkStarImage from '@ask-cf/assets/images/pink_star.svg'
import UserSidebarLoggedOut from '@ask-cf/assets/images/user-sidebar-logged-out.webp'
import {
  ASK_CF_FF_BADGES,
  ASK_CF_FF_FOLLOW,
  ASK_CF_FF_QUESTIONS_RECOMMENDED,
  ASK_CF_FF_QUESTIONS_TO_ANSWER,
  ASK_CF_FF_SUPERSKILL,
  ASK_CF_FF_YOUR_ANSWERS,
  ASK_CF_FF_YOUR_QUESTIONS,
  ASK_CF_FF_YOUR_QUESTIONS_COUNT,
} from '@ask-cf/config'
import { ROUTES } from '@ask-cf/constants/app-routes'
import { useIsActiveRoute } from '@ask-cf/hooks/use-is-active-route'
import { useUser } from '@ask-cf/hooks/use-user-data'
import { UserRank, UserRankType, lastRankType, userRanks } from '@ask-cf/utils/user-ranks'
import { CurrentUserAvatar } from '../avatar'
import { LoginButton } from '../login-button'
import { SignUpButton } from '../sign-up-button'
import { StyledLink } from '../styled-link'
import { UserNameLabel } from '../user-name-label'

const showNotReadySections =
  ASK_CF_FF_SUPERSKILL ||
  ASK_CF_FF_BADGES ||
  ASK_CF_FF_YOUR_QUESTIONS ||
  ASK_CF_FF_YOUR_ANSWERS ||
  ASK_CF_FF_FOLLOW ||
  ASK_CF_FF_QUESTIONS_TO_ANSWER ||
  ASK_CF_FF_QUESTIONS_RECOMMENDED

export function UserProfilePanel() {
  const user = useUser()
  // eslint-disable-next-line local/codegen-query-patterns
  const isUserLoggedIn = useIsLoggedInQuery()
  return (
    <div className="overflow-hidden rounded-[12px]">
      {user.isLoading ? (
        <LoadingProfile />
      ) : isUserLoggedIn.data ? (
        <LoggedInProfile user={user} />
      ) : (
        <LoggedOutProfile />
      )}
    </div>
  )
}

export function LoadingProfile() {
  return (
    <div className="flex flex-col items-center rounded-[12px] bg-white px-4 pb-6 pt-[34px]">
      <SkeletonItem className="h-[56px] w-[56px] rounded-full" />
      <SkeletonItem className="mt-4" />
      {showNotReadySections ? (
        <>
          <SkeletonItem className="mt-1" />
          <SkeletonItem className="mt-5 h-[30px]" />
          <SkeletonItem className="mt-10" />
          <SkeletonItem className="mt-5" />
          <SkeletonItem className="mt-5" />
          <SkeletonItem className="mt-5" />
          <SkeletonItem className="mt-5" />
        </>
      ) : null}
    </div>
  )
}

export function LoggedInProfile({
  user,
  onItemClick,
}: {
  user: ReturnType<typeof useUser>
  onItemClick?: () => void
}) {
  const locale = useLocale()
  const currentRank: UserRankType = UserRankType.CreativeNewbie // This will be fetched from the backend
  const nextRank: UserRankType | null = UserRankType.CreativeMentor // This will be fetched from the backend or resolved on the client
  const currentRankData = userRanks[currentRank]
  const nextRankData = nextRank && userRanks[nextRank]
  const BadgeIcon = currentRankData?.badgeIcon
  const t = useTranslations('ask_cf.components.user_profile_panel.statistics')
  const userName =
    user.data?.firstName || user.data?.lastName
      ? `${user.data?.firstName} ${user.data?.lastName}`
      : undefined

  const userProfilePanelQuery = useUserProfilePanelQuery(undefined, {
    select: data => data.me?.user?.superSkill,
  })
  const superSkill = userProfilePanelQuery.data

  const { data: userStats } = useAskCfGetUserStatsQuery({
    input: { userId: user.data?.id || '' },
  })

  const { questionCount, answerCount, questionsFollowedCount } = userStats?.askCFGetUserStats
    ?.stats || {
    questionCount: 0,
    answerCount: 0,
    questionsFollowedCount: 0,
  }

  return (
    <div className="flex flex-col bg-white">
      <LoggedInProfileHeader rankData={currentRankData} />
      <div className="relative">
        <Link
          href={ROUTES.MY_ACCOUNT}
          target="_blank"
          className={tw(
            'group relative -mt-[28px] flex w-full flex-col items-center justify-center',
            showNotReadySections ? 'pb-5' : 'pb-6',
          )}
        >
          <SettingsIcon className="text-text-hover absolute right-2 top-8 hidden h-5 w-5 group-hover:block" />
          <CurrentUserAvatar
            size="semiLarge"
            className={tw(
              'transition-transform duration-100 group-hover:scale-110',
              currentRank === UserRankType.CreativeNewbie ? 'border-0' : 'border-2',
            )}
            style={{
              borderColor:
                currentRank === UserRankType.CreativeNewbie ? undefined : currentRankData.color,
            }}
          />

          <p className="text-text-primary group-hover:text-text-hover mt-5 text-center text-lg font-medium">
            <UserNameLabel>{userName}</UserNameLabel>
          </p>
          <div className="text-center text-xs font-medium">{superSkill}</div>
        </Link>
        {ASK_CF_FF_BADGES && BadgeIcon ? (
          <BadgeIcon
            className={'absolute left-[calc(50%+13px)] top-[6px]'}
            style={{ color: currentRankData.color }}
          />
        ) : null}
        {ASK_CF_FF_BADGES ? <LoggedInProfileProgressSection nextRankData={nextRankData} /> : null}

        <div className="h-[1px] w-full bg-blue-100" />
        {ASK_CF_FF_YOUR_QUESTIONS ||
        ASK_CF_FF_YOUR_ANSWERS ||
        ASK_CF_FF_FOLLOW ||
        ASK_CF_FF_QUESTIONS_TO_ANSWER ||
        ASK_CF_FF_QUESTIONS_RECOMMENDED ? (
          <div className="flex flex-col gap-5 pb-8 pl-4 pt-4">
            {ASK_CF_FF_YOUR_QUESTIONS ? (
              <StatisticsRow
                count={questionCount}
                label={t('your_questions')}
                href={ROUTES.YOUR_QUESTIONS(locale)}
                onClick={onItemClick}
                showCount={ASK_CF_FF_YOUR_QUESTIONS_COUNT}
              />
            ) : null}
            {ASK_CF_FF_YOUR_ANSWERS ? (
              <StatisticsRow
                count={answerCount}
                label={t('your_answers')}
                href={ROUTES.YOUR_ANSWERS(locale)}
                onClick={onItemClick}
              />
            ) : null}
            {ASK_CF_FF_FOLLOW ? (
              <StatisticsRow
                count={questionsFollowedCount}
                label={t('questions_you_follow')}
                href={ROUTES.YOUR_FOLLOWED_QUESTIONS(locale)}
                onClick={onItemClick}
              />
            ) : null}
            {ASK_CF_FF_QUESTIONS_TO_ANSWER ? (
              <StatisticsRow count={100} label={t('questions_to_answer')} />
            ) : null}
            {ASK_CF_FF_QUESTIONS_RECOMMENDED ? (
              <div className="text-text-tertiary flex items-center gap-2 pl-2">
                <ContactSupportIcon />
                <span className="text-sm font-medium leading-5">{t('recommended_questions')}</span>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}

function StatisticsRow({
  count,
  label,
  href,
  onClick,
  showCount = true,
}: {
  count: number
  label: string
  href?: string
  onClick?: () => void
  showCount?: boolean
}) {
  const isActiveRoute = useIsActiveRoute()

  const content = (
    <>
      {showCount ? (
        <Tag className="bg-background-primary border-0 text-sm leading-[16px]">{count}</Tag>
      ) : null}
      <span className={'text-sm font-medium leading-5'}>{label}</span>
    </>
  )

  const containerClasses = 'flex items-center gap-2'

  return href ? (
    <StyledLink
      className={containerClasses}
      href={href}
      active={isActiveRoute(href)}
      onClick={onClick}
      aria-label={label}
    >
      {content}
    </StyledLink>
  ) : (
    <div className={tw(containerClasses, 'text-text-tertiary')}>{content}</div>
  )
}

function LoggedInProfileProgressSection({ nextRankData }: { nextRankData: UserRank | null }) {
  return ASK_CF_FF_BADGES ? (
    nextRankData ? (
      <ProgressToNextRank nextRankData={nextRankData} />
    ) : (
      <LastRankProgressSection />
    )
  ) : null
}

function LastRankProgressSection() {
  const t = useTranslations('ask_cf.components.user_profile_panel')
  const rankData = userRanks[lastRankType]
  const BadgeIcon = rankData.badgeIcon as React.FC<React.SVGProps<SVGSVGElement>>
  return (
    <div className="relative mb-[9px] mt-1.5 flex w-full justify-center pb-7 pt-[13px]">
      <div className="text-text-tertiary relative text-xs font-medium leading-[20px]">
        {t('last_rank_progress_label', { rankLabel: t(`ranks.${rankData.translationKey}`) })}
        <BadgeIcon
          className="absolute -right-1 top-[50%] -translate-y-[50%] translate-x-[100%]"
          style={{ color: rankData.color }}
        />
        <img
          loading="lazy"
          className="absolute -left-3 -top-[10px]"
          src={PinkHollowStarImage.src}
          alt=""
          width="13"
          height="13"
        />
        <img
          loading="lazy"
          src={BlueHollowStarImage.src}
          className="absolute -left-5 top-[3px]"
          alt=""
          width="15"
          height="16"
        />
        <img
          loading="lazy"
          src={PinkStarImage.src}
          className="absolute -bottom-[22px] -right-[48px]"
          alt=""
          width="33"
          height="33"
        />
      </div>
    </div>
  )
}

function ProgressToNextRank({ nextRankData }: { nextRankData: UserRank }) {
  const t = useTranslations('ask_cf.components.user_profile_panel')
  const BadgeIcon = nextRankData.badgeIcon
  const rankLabel = t(`ranks.${nextRankData.translationKey}`)
  const locale = useLocale()
  return (
    <Link href={ROUTES.BADGES(locale)} className="relative flex flex-col items-center px-6 pb-6">
      <Progress.Root
        value={50}
        className="bg-background-component relative h-1.5 w-[calc(100%-15px)] overflow-hidden rounded-full bg-opacity-80"
        aria-label={t(`progress_bar_label`, { rankLabel })}
      >
        <Progress.Indicator
          className="h-full"
          style={{ width: '50%', backgroundColor: nextRankData.color }}
        />
      </Progress.Root>

      {BadgeIcon ? (
        <BadgeIcon className="absolute -top-[13px] right-6" style={{ color: nextRankData.color }} />
      ) : null}

      <div className="text-text-quinary mt-1 text-center text-xs font-medium leading-[20px]">
        {t(`progress_bar_label`, { rankLabel })}
      </div>
    </Link>
  )
}

function LoggedInProfileHeader({ rankData }: { rankData: UserRank }) {
  const t = useTranslations('ask_cf.components.user_profile_panel.ranks')
  return (
    <div
      className={tw(
        'bg-cover bg-top px-[14px] pt-[18px] text-center ',
        showNotReadySections ? 'tablet:h-[77px] h-[110px]' : 'tablet:h-[45px] h-[75px]',
      )}
      style={{ backgroundImage: `url(${rankData.backgroundImageUrl})` }}
    >
      {ASK_CF_FF_BADGES ? (
        <span className="text-sm font-semibold leading-5" style={{ color: rankData.fontColor }}>
          {t(rankData.translationKey)}
        </span>
      ) : null}
    </div>
  )
}

function LoggedOutProfile() {
  const t = useTranslations('ask_cf.components.user_profile_panel')
  return (
    <div
      className="flex flex-col items-center bg-cover bg-top px-[14px] pb-3 pt-5"
      style={{ backgroundImage: `url(${UserSidebarLoggedOut.src})` }}
    >
      <div className="max-w-[1790px] whitespace-pre-wrap text-center text-sm font-semibold leading-5 text-blue-700">
        {t('logged_out_title')}
      </div>
      <div className="mt-[14px] flex items-center justify-center gap-4 rounded-[13px] bg-white px-[37.5px] py-2">
        <SignUpButton spanClassName="text-sm leading-5 text-text-tertiary hover:text-blue-700" />
        <LoginButton className="text-text-tertiary text-sm leading-5 hover:text-blue-700" />
      </div>
    </div>
  )
}
