import { Slot } from 'primitives/slot'
import * as React from 'react'
import { tw } from 'utils/classnames'

/**
 * `primary` - the default button style, white icon when hover and off-white when active
 * `violet` - a violet button with white icon
 * `inactive` - when the button is inactive yet still clickable
 * `disabled` - similar styling to inactive although the button is not clickable
 */
export type Variant = 'primary' | 'violet' | 'transparent' | 'disabled' | 'inactive'

type IconButtonProps = React.ComponentProps<'button'> & {
  variant?: Variant
  asChild?: boolean
}

export const IconButton = React.forwardRef(function IconButton(
  { variant = 'primary', asChild, ...props }: IconButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  const Comp = asChild ? Slot : 'button'

  return (
    <Comp
      type="button"
      {...props}
      ref={ref}
      className={tw(
        'rounded-2 flex size-8 cursor-pointer items-center justify-center border-0',
        'outline-none focus-visible:ring-2 focus-visible:ring-violet-500',
        variant === 'primary' &&
          'hover:text-service-gray-900 focus-visible::text-service-white-white active:text-service-white-white group bg-gray-800 text-gray-300 hover:bg-gray-900 active:bg-violet-500',
        variant === 'violet' && 'text-service-white-white bg-violet-500',
        variant === 'transparent' && 'text-gray-300 hover:text-gray-50',
        variant === 'disabled' && 'cursor-not-allowed text-gray-600 opacity-50 outline-none',
        variant === 'inactive' && 'text-gray-600 opacity-50 outline-none hover:text-gray-100',
        props.className,
      )}
    />
  )
})
